import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CampaignParam } from '../layouts/authorized/order/classes/CampaignParam';
import { OrderTree } from '../layouts/classes/response/order/OrderTree';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private http: HttpClient) {}
  V2_URL: any = environment.API_URL + '/v2/order/image';

  findAll(filter: any) {
    let params = new HttpParams();
    if (filter.pageIndex !== undefined) {
      params = params
        .set('pageIndex', (filter.pageIndex - 1).toString())
        .set('pageSize', filter.pageSize);
    }

    if (filter.selectedStatus) {
      params = params.set('status', filter.selectedStatus);
    }

    if (filter.orderNo) {
      params = params.set('orderNo', filter.orderNo);
    }

    if (filter.orderName) {
      params = params.set('orderName', filter.orderName);
    }

    if (filter.startDate && filter.endDate) {
      params = params
        .set('startDate', filter.startDate)
        .set('endDate', filter.endDate);
    }

    return this.http.get(`${environment.API_URL}/dsp/order`, { params });
  }

  findOrderTree(filter: any): Observable<OrderTree[]> {
    let params = new HttpParams();

    if (filter.selectedStatus) {
      params = params.set('status', filter.selectedStatus);
    }
    if (filter.orderNo) {
      params = params.set('orderNo', filter.orderNo);
    }
    if (filter.orderName) {
      params = params.set('orderName', filter.orderName);
    }
    if (filter.startDate && filter.endDate) {
      params = params
        .set('startDate', filter.startDate)
        .set('endDate', filter.endDate);
    }
    return this.http.get<OrderTree[]>(`${environment.API_URL}/dsp/order/list`, {
      params,
    });
  }

  findById(orderId: any) {
    return this.http.get(`${environment.API_URL}/dsp/order/${orderId}`);
  }

  findAllOrderProperty(orderId: any) {
    const params = new HttpParams().set('orderId', orderId);
    return this.http.get(`${environment.API_URL}/dsp/order/property/image`, {
      params,
    });
  }

  findLogs(
    orderIds: any,
    startDate,
    pageIndex: any,
    pageSize: any,
    logTypes: string,
    buildingIds: any,
    logStartDate: any,
    logEndDate: any
  ) {
    let params = new HttpParams()
      .set('orderIds', orderIds)
      .set('startDate', startDate)
      .set('pageIndex', pageIndex)
      .set('pageSize', pageSize)
      .set('logTypes', logTypes);

    if (buildingIds && buildingIds.length) {
      params = params.set('buildingIds', buildingIds);
    }
    if (logStartDate) {
      params = params.set('searchStartDate', logStartDate);
      params = params.set('searchEndDate', logEndDate);
    }
    return this.http.get(`${environment.API_URL}/dsp/order/logs`, { params });
  }

  getLogSummary(
    orderIds: any,
    startDate,
    logTypes: string,
    propertyIds: any,
    searchStartDate: any,
    searchEndDate: any
  ) {
    let params = new HttpParams()
      .set('orderIds', orderIds)
      .set('startDate', startDate)
      .set('logTypes', logTypes);

    if (propertyIds && propertyIds.length) {
      params = params.set('buildingIds', propertyIds);
    }
    if (searchStartDate) {
      params = params.set('searchStartDate', searchStartDate);
      params = params.set('searchEndDate', searchEndDate);
    }
    return this.http.get(`${environment.API_URL}/dsp/order/logs/summary`, {
      params,
    });
  }

  delete(orderId: any) {
    return this.http.delete(`${environment.API_URL}/dsp/order/${orderId}`);
  }

  findOrderDates() {
    return this.http.get(`${environment.API_URL}/dsp/order/dates`);
  }

  findSubCategory(categoryId: any) {
    return this.http.get(
      `${environment.API_URL}/category/${categoryId}/subcategory`
    );
  }

  findAllCategory() {
    return this.http.get(`${environment.API_URL}/category/parent`);
  }

  findAllPropertyList(filter: any) {
    const data = this.getData(filter);
    return this.http.post(`${environment.API_URL}/sales/property/list`, data);
  }

  findAllPropertyMap(filter: any) {
    const data = this.getData(filter);
    return this.http.post(`${environment.API_URL}/sales/property/map`, data);
  }

  getData(filter: any) {
    const data: any = {};

    if (filter.propertyName && filter.propertyName !== '') {
      data.propertyName = filter.propertyName;
    }
    if (filter.cities.length > 0) {
      data.cities = filter.cities;
    }
    // Property attributes filter
    if (filter.propertyType.length > 0) {
      data.propertyType = filter.propertyType;
    }
    if (filter.floorNumber && filter.floorNumber !== '') {
      data.floorNumber = filter.floorNumber;
    }
    if (filter.occupancyRate && filter.occupancyRate !== '') {
      data.occupancyRate = filter.occupancyRate;
    }
    if (filter.rent.length > 0) {
      data.rent = filter.rent;
    }
    if (filter.propertyCost && filter.propertyCost !== '') {
      data.propertyCost = filter.propertyCost;
    }
    if (filter.propertyAge.length > 0) {
      data.buildingAge = filter.propertyAge;
    }
    if (filter.parking.length > 0) {
      data.parkingSpace = filter.parking;
    }
    // population attributes filter
    if (filter.genderRatio) {
      data.genderRatio = filter.genderRatio;
    }
    if (filter.married) {
      data.married = filter.married;
    }
    if (filter.education.length > 0) {
      data.educationRatio = filter.education;
    }
    if (filter.age.length > 0) {
      data.ageRatio = filter.age;
    }
    if (filter.vehicle) {
      data.vehicle = filter.vehicle;
    }
    if (filter.consumptions.length > 0) {
      data.consumptionRatio = filter.consumptions;
    }
    if (filter.incomes.length > 0) {
      data.incomes = filter.incomes;
    }

    if (filter.pointOfLift) {
      data.pointOfLift = filter.pointOfLift;
    }

    if (filter.videoTime) {
      data.videoTime = filter.videoTime;
    }

    if (filter.startDate) {
      data.startDate = filter.startDate;
    }

    if (filter.endDate) {
      data.endDate = filter.endDate;
    }
    if (filter.circles) {
      data.circles = filter.circles;
    }
    if (filter.adType) {
      data.adType = filter.adType;
    }
    return data;
  }

  findAllCity() {
    return this.http.get(`${environment.API_URL}/sales/city`);
  }

  findPropertyInfo(propertyId: any) {
    const params = new HttpParams().set('buildingId', propertyId);
    return this.http.get(`${environment.API_URL}/sales/property/info`, {
      params,
    });
  }

  dspOrderSave(data: any): Observable<any> {
    return this.http.post(`${environment.API_URL}/dsp/order`, data);
  }

  uploadFiles(orderId: any, files: any) {
    return this.http.post(
      `${environment.API_URL}/file/upload/dspcartfile/${orderId}`,
      files
    );
  }

  findAllPoints(propertyId: number) {
    return this.http.get(
      `${environment.API_URL}/sales/property/${propertyId}/points`
    );
  }

  findAllResources(orderId: number, cityIds: any, propertyIds: any) {
    let params = new HttpParams();
    if (propertyIds && propertyIds.length) {
      params = params.set('propertyIds', propertyIds);
    }
    if (cityIds && cityIds.length) {
      params = params.set('cityIds', cityIds);
    }
    return this.http.get(
      `${environment.API_URL}/advertisement/point/detail/${orderId}`,
      { params }
    );
  }

  findImpression(orderId: any, type: any) {
    const params = new HttpParams().set('orderId', orderId).set('type', type);
    return this.http.get(`${environment.API_URL}/advertisement/impression`, {
      params,
    });
  }

  calculateCampaignImpression(campaign: any) {
    let totalImpression = 0;
    if (campaign.impressionPerDays) {
      const impressionPerDays = campaign.impressionPerDays;
      impressionPerDays.split(',').forEach((impression) => {
        const imp = +impression;
        totalImpression += imp;
      });
    }
    return totalImpression;
  }

  findImpressionPerDays(pointIds: any, startDate: any, endDate: any) {
    const data = {
      pointIds,
      startDate,
      endDate,
    };
    return this.http.post(`${environment.API_URL}/sales/impression`, data);
  }

  takeScreenshot(data: any) {
    return this.http.post(
      `${environment.API_URL}/advertisement/screenshot`,
      data
    );
  }

  findAllScreenshot(campaignId: number, cityIds: any, propertyIds: any) {
    let params = new HttpParams();

    if (propertyIds && propertyIds.length) {
      params = params.set('propertyIds', propertyIds);
    }
    if (cityIds && cityIds.length) {
      params = params.set('cityIds', cityIds);
    }

    return this.http.get(
      `${environment.API_URL}/advertisement/screenshot/${campaignId}`,
      { params }
    );
  }
  findAllCampaignImage(data: CampaignParam) {
    let params = new HttpParams();

    if (data.propertyIds && data.propertyIds.length) {
      params = params.set('propertyIds', data.propertyIds);
    }
    if (data.cityIds && data.cityIds.length) {
      params = params.set('cityIds', data.cityIds);
    }
    if (data.imageDate && data.imageDate.length) {
      params = params.set('imageDate', data.imageDate);
    }
    data.pageIndex = data.pageIndex > 0 ? data.pageIndex : 1;
    params = params.set('pageIndex', data.pageIndex.toString());
    params = params.set('pageSize', data.pageSize.toString());

    return this.http.get(
      `${environment.API_URL}/advertisement/campaignImages/${data.orderId}`,
      { params }
    );
  }
  downloadImagebyOrderId(orderId: number, date: string): Observable<any> {
    const params = new HttpParams()
      .set('orderId', orderId.toString())
      .set('date', date);
    return this.http.get(this.V2_URL + '/download', {
      params,
      responseType: 'text',
    });
  }

  downloadPdfOfImageByOrderId(orderId: number, date: string): Observable<any> {
    const params = new HttpParams()
      .set('orderId', orderId.toString())
      .set('date', date);
    return this.http.get(this.V2_URL + '/download/pdf', {
      params,
      responseType: 'text',
    });
  }
  getLogs(data: any): Observable<any> {
    let params = new HttpParams();

    params = params.set('orderId', data.orderId);
    if (data.propertyId != null)
      params = params.set('propertyId', data.propertyId);

    if (data.min != null) {
      params = params.set('min', data.min);
    }
    if (data.days != null) {
      params = params.set('days', data.days);
    }
    if (data.startDate != null) {
      params = params.set('startDate', data.startDate);
    }
    if (data.startTime != null) {
      params = params.set('startTime', data.startTime);
    }
    if (data.endDate != null) {
      params = params.set('endDate', data.endDate);
    }
    if (data.endTime != null) {
      params = params.set('endTime', data.endTime);
    }
    return this.http.get(`${environment.API_URL}/v2/order/log`, { params });
  }

  findActiveNonActiveScreens(
    orderId: any,
    selectedProperties: any,
    action: any
  ) {
    let params = new HttpParams().set('orderId', orderId).set('action', action);

    if (selectedProperties && selectedProperties.length) {
      params = params.set('buildingIds', selectedProperties);
    }
    return this.http.get(
      `${environment.API_URL}/advertisement/active-nonactive-screens`,
      { params }
    );
  }

  exportLogSummaryPDF(campaignId: number) {
    return this.http.get(
      `${environment.API_URL}/campaign/log/summary/${campaignId}/pdf`
    );
  }
  exportLogSummaryExcel(campaignId: number) {
    return this.http.get(
      `${environment.API_URL}/campaign/log/summary/${campaignId}`
    );
  }
}
